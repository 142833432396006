import { Component, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { FormGroup, NgForm } from '@angular/forms';
import { apiurl } from 'src/app/_factory/_api';
import { AgentService, CustomService } from 'src/app/_factory';
@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css'],
  providers: [MessageService, PrimeNGConfig]
})
export class SignatureComponent implements OnInit {
  formInput: FormGroup;
  formAttribute: any = {
    signature: ''
  };
  submitted = false;
  constructor(private _AgentService: AgentService, private messageService: MessageService, private _CustomService: CustomService) { }

  ngOnInit() {
    this.getSignature();
  }

  getSignature = () => {
    const url = apiurl.account.signature;
    this._AgentService.httpCall(url, 'GET', null, null, null).subscribe((res) => {
      if (res.responseCode == 200) {
        this.formAttribute.signature = res.result.signature || ''
      } else {
        this.toastMsg('error', 'Error', 'something went wrong')
      }
    })

  }
  toastMsg(severity, summary, detail) {
    this.messageService.add({ key: 'tc', severity: severity, summary: summary, detail: detail });
  }
  handleSubmit = (myForm: NgForm) => {
    this.submitted = true;
    if (myForm.form.status !== 'INVALID') {
      const url = apiurl.account.signature;
      this._AgentService.httpCall(url, 'POST', this.formAttribute, null, null).subscribe((res) => {
        if (res.responseCode == 200) {
          this.toastMsg('success', 'Success', 'Signature updated Successfully')
          this.getSignature();
        } else {
          this.toastMsg('error', 'Error', 'something went wrong')
        }
      })
    } else {
      this.toastMsg('error', 'Form Validation Error', 'Please fill all required fields')
    }
  }
}
