import { Component, Inject, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
})
export class EmailComponent implements OnInit {
  entity;
  objData = null;
    submitted = false;
   
  redirectUrl = null;
    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }
    
  data;
    ngOnInit() {
    
    this.data = this.config.data;
    this.objData = this.data.data.objData;
  }
  handleEmailSand=(values)=>{
    setTimeout(()=>{
      this.ref.close(values);
    },2500)
  }
}
