import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AccordionModule } from 'primeng/accordion';
import { SliderModule } from 'primeng/slider';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MenuModule } from 'primeng/menu';
import { DigitOnlyDirective } from '../_common/_directory/digit-only.directive';
import { BnNgTreeModule } from 'bn-ng-tree-lib';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressBarModule } from 'primeng/progressbar';
import { PanelModule } from 'primeng/panel';
import { MessageModule } from 'primeng/message';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TableModule } from 'primeng/table';
import { DragDropModule } from 'primeng/dragdrop';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';


@NgModule({
    declarations: [DigitOnlyDirective],
    imports: [
        CommonModule, DragDropModule, ReactiveFormsModule, FormsModule, TableModule,
        /* primeng modules */
        PaginatorModule, DialogModule, CalendarModule, ToastModule, ProgressSpinnerModule, AccordionModule, SliderModule, MenuModule, BnNgTreeModule, ScrollPanelModule, OverlayPanelModule, InputSwitchModule, DynamicDialogModule, FileUploadModule, ProgressBarModule, PanelModule, MessageModule, TooltipModule, DropdownModule, EditorModule, TabViewModule, MultiSelectModule, RichTextEditorAllModule, AngularEditorModule, ConfirmPopupModule, ConfirmDialogModule
    ],
    exports: [
        CommonModule, DragDropModule, ReactiveFormsModule, FormsModule, TableModule,
        /* custom modules */
        DigitOnlyDirective,
        /* primeng modules */
        PaginatorModule, DialogModule, CalendarModule, ToastModule, ProgressSpinnerModule, AccordionModule, SliderModule, MenuModule, BnNgTreeModule, ScrollPanelModule, OverlayPanelModule, InputSwitchModule, DynamicDialogModule, FileUploadModule, ProgressBarModule, PanelModule, MessageModule, TooltipModule, DropdownModule, EditorModule, TabViewModule, MultiSelectModule, RichTextEditorAllModule, AngularEditorModule, ConfirmPopupModule, ConfirmDialogModule
    ]
})
export class NgPrimeModule { }
