import { Component, Input, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AgentService } from 'src/app/_factory';
import { apiurl } from 'src/app/_factory/_api';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css'],
  providers: [MessageService, PrimeNGConfig]
})
export class DocumentComponent implements OnInit {
  @Input() entityKey;
  @Input() entityType;
  limit = environment.pageLimit;
  page = 0;
  documentList = null;
  uploadedFiles: any[] = [];
  fileData = null;
  selectedDocId;
  fileUploadNumber = 0;
  isUploadBar = false;
  constructor(private _AgentService: AgentService, private messageService: MessageService) { }
  ngOnInit() {
    this.getDocument()
  }
  getDocument = () => {
    const apiPath = apiurl.documents.list;
    const searchParam = {
      'query': '',
      'entityKey': this.entityKey,
      'entityType': this.entityType,
      'page': this.page.toString(),
      'limit': this.limit.toString(),
    }
    const headers = searchParam
    this._AgentService.httpCall(apiPath, 'Get', null, searchParam, headers).subscribe((res) => {
      if (res.responseCode == 200) {
        this.documentList = res.result;
      } else {
        this.documentList = []
        console.log('something went wrong')
      }
    }, (errors) => {
      var res = errors.error;
      this.toastMsg('error', 'Error', `${res.messege || 'Faliure'}`)
    })
  }
  paginate(event) {
    if (this.page !== event.page) {
      this.page = event.page;
      this.getDocument()
    }
  }
  toastMsg(severity, summary, detail) {
    this.messageService.add({ key: 'tc', severity: severity, summary: summary, detail: detail });
  }
  showConfirm() {
    this.messageService.clear();
    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Confirm to proceed' });
  }
  onConfirm() {
    this.messageService.clear('c');
    const url = apiurl.documents.id.replace('{id}', this.selectedDocId.toString());
    this._AgentService.httpCall(url, 'delete', null, null, {}).subscribe((res) => {
      if (res.responseCode == 200) {
        this.getDocument()
        this.toastMsg('success', 'Success', 'Delete Successfully')
      } else {
        this.toastMsg('error', 'Error', 'something went wrong')
      }
    }, (errors) => {
      var res = errors.error;
      this.toastMsg('error', 'Error', `${res.messege || 'Faliure'}`)
    })
  }
  onReject() {
    this.messageService.clear('c');
  }
  handleDelete = (id: number) => {
    this.selectedDocId = id;
    this.showConfirm()
  }
  myUploader = (event, control) => {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    event.files.forEach((element, index) => {
      this.fileData = <File>event.files[index];
      let url = apiurl.documents.upload;
      const formData = new FormData();
      formData.append('file', this.fileData);
      formData.append('entityKey', this.entityKey);
      formData.append('entityType', this.entityType);
      this.fileUploadNumber = 0;
      this.isUploadBar = true;
      var headers = { entityKey: this.entityKey, entityType: this.entityType, reportProgress: true, observe: 'events' }
      this._AgentService.httpCall(url, 'FileUpload', formData, null, headers).subscribe(res => {
        if (res && res['responseCode'] == 200) {
          this.fileUploadNumber = this.fileUploadNumber + 1;
          const newArray = [res.result].concat(this.documentList)
          this.documentList = newArray;
          if (this.fileUploadNumber == event.files.length) {
            this.isUploadBar = false;
            this.toastMsg('success', 'Success', 'Files uploaded successfully')
          }
        }
      }, (errors) => {
        var res = errors.error;
        this.toastMsg('error', 'Error', `${res.messege || 'Faliure'}`)
      })
    });
  }
  handleDwonload = (id: number) => {
    let url;
    let ext;
    let fileName;
    url = `/api/Document/Download?id=${id}`;
    ext = 'xlsx';
    fileName = 'Customer Purchase Report.xlsx'
    url = environment.production ? url : environment.baseUrl + url;
    var anchor = document.createElement("a");
    anchor.download = fileName;
    anchor.target = '_blank';
    anchor.href = `${url}`;
    anchor.target = '_blank';
    anchor.click();
  }
  removeImage = (target: any, str: string) => {
  }
}
