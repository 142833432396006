import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductFormComponent } from '../_features/product/product-form/product-form.component';


@Injectable({
  providedIn: 'root'
})
export class PendingChangesGuard implements CanDeactivate<ProductFormComponent> {
  canDeactivate(component: ProductFormComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.hasUnsavedData()) {
      let temp:any = component.displayConfirmDialog();
      // alert(temp)
      // return temp;
      return false;
    } else {
      return true;
    }
  }
}
