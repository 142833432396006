import { Component, OnInit, OnDestroy } from '@angular/core';
import { AgentService, CustomService } from 'src/app/_factory';
import { apiurl } from 'src/app/_factory/_api';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { environment } from '../../../environments/environment'
import { AuthService } from 'src/app/_factory/auth.service';
import { ActivatedRoute } from '@angular/router';
import { EmailComponent } from 'src/app/_features/shared/dialog/email/email.component';
export interface Item {
  id: string,
  name: string,
  capital: string,
  phone: string,
  currency: string
}
export interface DownLineItem {
  item: Item,
  parent: DownLineItem
}
@Component({
  selector: 'app-dashoard-list',
  templateUrl: './dashoard-list.component.html',
  styleUrls: ['./dashoard-list.component.scss'],
  providers: [MessageService, PrimeNGConfig]
})
export class DashoardListComponent implements OnInit, OnDestroy {
  date: Date[];
  items = [];
  AllowParentSelection = true;
  RestructureWhenChildSameName = false;
  ShowFilter = true;
  Disabled = false;
  FilterPlaceholder = 'Explore Department...';
  MaxDisplayed = 5;
  multipleSelected = [];
  customerList = {
    data: null,
    totalCount: 0
  }
  limit = 5;
  page = 0;
  apiPath = apiurl.customer.customer;
  selectedCustomerId;
  searchParam = {
    'query': '',
    'state': '',
    'department': '',
    'startDate': '',
    'endDate': '',
    'sortBy': '',
    'sortDirection': ''
  }
  master = {
    departmentList: []
  }
  departments = []
  selectedDepartmentIds;
  singleCustomerIds = []
  customerIds = []
  checkedAll = false;
  selectedAddress = null;
  isViewMoreAddress = false;
  rightSideBarPosition = 'right';
  fullCustomerList = [];
  nextPage = this.page + 1;
  checkedAllPage = false;
  totalCustomersSelected = 0;
  confirmMsgFor = '';
  emailConfig = {};
  cusoterEmailLimit = environment.cusoterEmailLimit;
  customerIdsBackup = [];
  addressData;
  printTemplate = 0;
  emailCustomers = [];
  constructor(private _AgentService: AgentService, private messageService: MessageService, private _AuthenticationService: AuthService, private _ActivatedRoute: ActivatedRoute, private _CustomService: CustomService,) { }
  ngOnInit() {
    this.getDepartmentListData();
    this._ActivatedRoute.queryParams.subscribe(params => {
      if (params.search) {
        this.searchParam.query = params.search;
      }
      this.getCustomerList();
    });
    this._AuthenticationService.getCustomerEmitter().subscribe((customObject) => {
      this.getDataFromSearch();
    });
  }
  handleSorting = (sortBy, sortDirection) => {
    this.searchParam.sortBy = sortBy;
    this.searchParam.sortDirection = sortDirection;
    this.getCustomerList();
  }
  ngOnDestroy = () => {
    this.searchParam = {
      'query': '',
      'state': '',
      'department': '',
      'startDate': '',
      'endDate': '',
      'sortBy': '',
      'sortDirection': ''
    }
    this.master = {
      departmentList: []
    }
    this.customerList = {
      data: null,
      totalCount: 0
    }
    this.items = []
  }
  private process(data): any {
    let result = [];
    result = data.map((item) => {
      return this.toTreeNode(item);
    });
    return result;
  }
  private toTreeNode(node, parent = null) {
    if (node && node.children) {
      node.children.map(item => {
        return this.toTreeNode(item, node);
      });
    }
    return node;
  }
  toastMsg(severity, summary, detail) {
    this.messageService.add({ key: 'tc', severity: severity, summary: summary, detail: detail });
  }
  getCustomerList = () => {
    const searchParam = {
      ...this.searchParam,
      'page': this.page.toString(),
      'limit': this.limit.toString(),
    }
    const headers = searchParam
    this._AgentService.httpCall(this.apiPath, 'Get', null, searchParam, headers).subscribe((res) => {
      if (res.responseCode == 200) {
        if (this.checkedAll) {
          res.result.data.map((item) => {
            item.checked = true;
          })
        } else {
          res.result.data.map((item) => {
            this.customerIdsBackup.map((ids) => {
              if (item.customerID == ids) {
                item.checked = true
              }
            })
          })
        }
        const fullCustomerList = [...this.fullCustomerList, ...res.result.data];
        const uniqueSet = Array.from(new Set(fullCustomerList.map(a => a.customerID)))
          .map(customerID => {
            return fullCustomerList.find(a => a.customerID === customerID)
          })
        this.fullCustomerList = uniqueSet;
        this.customerList = res.result;
        if (!this.checkedAll) {
          let selectedItems = [];
          this.customerList.data.map((item, index) => {
            if (item.checked) {
              selectedItems.push(item.customerID)
            }
          })
          if (selectedItems.length == this.customerList.data.length) {
            this.checkedAllPage = true
          } else {
            this.checkedAllPage = false
          }
        } else {
          this.postSelectAll();
        }
      } else {
        this.toastMsg('error', 'Error', 'something went wrong')
      }
    }, (errors) => {
      this.toastMsg('error', 'Error', 'Error on fetching Customer list')
    })
  }
  checkDepartment = (deptData) => {
    deptData.map((item, index) => {
      if (this.selectedDepartmentIds.indexOf(item.departmentID) > -1) {
        item.checked = true;
      }
      if (item.children && item.children.length > 0) {
        this.checkDepartment(item.children)
      }
    })
  }
  getDepartmentListData = () => {
    const apiPath = apiurl.departments.department_id.replace('{id}', '01');
    const headers = { 'page': this.page.toString(), 'limit': this.limit.toString() }
    this._AgentService.httpCall(apiPath, 'Get', null, null, headers).subscribe((res) => {
      if (res.responseCode == 200) {
        const data = [];
        data.push(res.result)
        this.master.departmentList = res.result.children;
        this.items = this.process(res.result.children);
      } else {
        console.log('something went wrong')
      }
    })
  }
  handleDatepicker = (value) => {
    const startDate = value[0];
    const endDate = value[1];
    if (startDate && endDate) {
      this.searchParam.startDate = startDate.toJSON();
      this.searchParam.endDate = endDate.toJSON();
      this.handleSearch();
    }
  }
  paginate(event) {
    if (this.page !== event.page) {
      if (this.page > event.page - 1) {
        this.nextPage = event.page - 1;
      } else {
        this.nextPage = event.page + 1;
      }
      this.page = event.page;
      this.getCustomerList()
    }
  }
  handleSearch = () => {
    this.page = 0;
    this.getCustomerList()
  }
  showConfirm(summary, detail) {
    this.messageService.clear();
    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: summary, detail: detail });
  }
  onConfirm() {
    this.messageService.clear('c');
    if (this.confirmMsgFor == 'remove-customer') {
      const url = apiurl.customer.customer_id.replace('{id}', this.selectedCustomerId.toString());
      this._AgentService.httpCall(url, 'delete', null, null, {}).subscribe((res) => {
        if (res.responseCode == 200) {
          this.getCustomerList()
          this.toastMsg('success', 'Success', 'Delete Successfully')
        } else {
          this.toastMsg('error', 'Error', 'something went wrong')
        }
      })
    } else if (this.confirmMsgFor == 'send-email') {
      this.openEmailDialog()
    }

  }
  onReject() {
    this.messageService.clear('c');
  }
  handleCustomerDelete = (customerID: number) => {
    this.selectedCustomerId = customerID
    this.confirmMsgFor = 'remove-customer';
    this.showConfirm('Are you sure?', 'Confirm to proceed')
  }
  getDataFromSearch = () => {
    this._ActivatedRoute.queryParams.subscribe(params => {
      const data: any = this._AuthenticationService.currentCustomerSearchValue
      if (params.search !== data.query) {
        this.searchParam.query = data.query;
        this.handleSearch()
      }
    });
  }
  handleExportSrvice = (customer, type) => {
    let url;
    let ext;
    if (type == 1) {
      url = apiurl.customer.downloadDoc.replace('{category}', customer.customerID)
      ext = 'docx'
    } else if (type == 2) {
      url = apiurl.customer.downloadExcel.replace('{category}', customer.customerID);
      ext = 'xlsx'
    }
    url = environment.production ? url : environment.baseUrl + url;
    var anchor = document.createElement("a");
    const fileName = customer.customerFirstName + ' ' + customer.customerLastName + ' Purchases.' + ext;
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();
  }
  handleCheckedItems(items) {
    var departmentIDs = items.map((item) => { return item.departmentID }).join(',');
    this.searchParam.department = departmentIDs;
    // this.departments = departments;
    this.handleSearch();
  }
  openEmailDialog = () => {
    this._CustomService.dialogComponentConfig(this.emailConfig, EmailComponent).subscribe(res => {
      if (res != undefined) {

      } else {

      }
    })
  }
  handleEmailDialog = (type, templateType) => {
    const customerfilters = {
      ...this.searchParam,
    }
    this.emailConfig = {
      header: 'Send Email',
      objData: {
        customerIds: type == 1 ? (this.checkedAll ? [] : this.customerIds) : this.singleCustomerIds,
        customerfilters: customerfilters,
        templateType: templateType,
        totalCustomersSelected: this.totalCustomersSelected,
        emailCustomers: this.emailCustomers,
        batchData: {
          batchId: 0,
          fromEmail: '',
          subject: ''
        }
      },
      width: '80%'
    }
    if (this.totalCustomersSelected > this.cusoterEmailLimit) {
      this.confirmMsgFor = 'send-email';
      this.showConfirm('Are you sure?', `You want to send ${this.totalCustomersSelected} customer`)
    } else {
      this.confirmMsgFor = '';
      this.openEmailDialog()
    }
  }

  handleItemEmail = (id) => {
    this.singleCustomerIds = []
    this.singleCustomerIds.push(id);
    this.handleEmailDialog(2, 1)
  }
  handleViewMoreAddress = (addresses) => {
    this.selectedAddress = addresses;
    this.isViewMoreAddress = true;
    this.rightSideBarPosition = 'right'
  }
  handleCloseAddressDialog = () => {
    this.isViewMoreAddress = false;
  }

  handleResetSearch = () => {
    this.searchParam = {
      'query': '',
      'state': '',
      'department': '',
      'startDate': '',
      'endDate': '',
      'sortBy': '',
      'sortDirection': ''
    }
    this.multipleSelected = [];
    this.date = null;
    this.page = 0;
    this.getCustomerList()
  }


  /* select items */
  postSelectAll = () => {
    this.checkedAllPage = this.checkedAll;
    const customerIdsBackup = []
    const emailCustomers = [];
    this.fullCustomerList.map((list) => {
      if (list.checked) {
        customerIdsBackup.push(list.customerID)
        const customerEmails = list.customerEmail && list.customerEmail.trim() || null;
        const tempEmail = customerEmails && customerEmails.length > 0 && list.customerEmail.split(',');
        if (tempEmail && tempEmail.length > 0) {
          tempEmail.map((emailItem) => {
            const customerEmail = emailItem && emailItem.trim();
            if (customerEmail && customerEmail !== '') {
              let data = {
                customerID: list.customerID,
                customerEmail: customerEmail,
              }
              emailCustomers.push(data)
            }
          })
        }
      }
    })
    this.emailCustomers = emailCustomers;
    this.customerIds = customerIdsBackup;
    this.customerIdsBackup = customerIdsBackup;
    this.totalCustomersSelected = this.checkedAll ? this.customerList.totalCount : 0;
  }
  postCheckBoxSelection = (customerIdsBackup) => {
    this.customerIds = customerIdsBackup;
    this.customerIdsBackup = customerIdsBackup;
    this.totalCustomersSelected = customerIdsBackup.length;
    if (this.totalCustomersSelected == this.customerList.totalCount) {
      this.checkedAll = true;
    } else {
      this.checkedAll = false;
      let temp = 0;
      this.customerList.data.map((item, index) => {
        if (item.checked) {
          temp = temp + 1
        }
      })
      if (this.customerList.data.length == temp) {
        this.checkedAllPage = true
      } else {
        this.checkedAllPage = false
      }
    }
  }
  handleSelect = (item, index) => {
    const customerIdsBackup = [];
    const emailCustomers = [];
    this.fullCustomerList.map((list) => {
      if (list.customerID == item.customerID) {
        list.checked = item.checked
      }
      if (list.checked) {
        customerIdsBackup.push(list.customerID)
        const customerEmails = list.customerEmail && list.customerEmail.trim() || null;
        const tempEmail = customerEmails && customerEmails.length > 0 && list.customerEmail.split(',');
        if (tempEmail && tempEmail.length > 0) {
          tempEmail.map((emailItem) => {
            const customerEmail = emailItem && emailItem.trim();
            if (customerEmail && customerEmail !== '') {
              let data = {
                customerID: list.customerID,
                customerEmail: customerEmail,
              }
              emailCustomers.push(data)
            }
          })
        }
      }
    })
    this.emailCustomers = emailCustomers;
    this.postCheckBoxSelection(customerIdsBackup)
  }

  handleSelectAllPage = () => {
    const customerIdsBackup = [];
    const emailCustomers = [];
    this.customerList && this.customerList.data.map((item, index) => {
      item.checked = this.checkedAllPage
    })
    this.fullCustomerList.map((item) => {
      this.customerList.data.map((customer) => {
        if (item.customerID == customer.customerID) {
          item.checked = customer.checked
        }
      })
    })
    this.fullCustomerList.map((list) => {
      if (list.checked) {
        customerIdsBackup.push(list.customerID)
        const customerEmails = list.customerEmail && list.customerEmail.trim() || null;
        const tempEmail = customerEmails && customerEmails.length > 0 && list.customerEmail.split(',');
        if (tempEmail && tempEmail.length > 0) {
          tempEmail.map((emailItem) => {
            const customerEmail = emailItem && emailItem.trim();
            if (customerEmail && customerEmail !== '') {
              let data = {
                customerID: list.customerID,
                customerEmail: customerEmail,
              }
              emailCustomers.push(data)
            }
          })
        }
      }
    })
    this.emailCustomers = emailCustomers;
    this.postCheckBoxSelection(customerIdsBackup)
  }
  handleSelectAll = () => {
    this.customerList && this.customerList.data.map((item, index) => {
      item.checked = this.checkedAll
    })
    this.fullCustomerList.map((item) => {
      this.customerList.data.map((customer) => {
        if (item.customerID == customer.customerID) {
          item.checked = customer.checked
        }
      })
    })
    this.postSelectAll();
  }
  handlePrint = (item) => {
    this.printTemplate = 1;
    this.addressData = item;
    setTimeout(() => {
      var printwin = window.open('', '', 'width=800,height=1000');
      printwin.document.write(document.getElementById("TARGET").innerHTML);
      printwin.document.write("<style> @page { size: auto; margin: 0mm; } </style>");
      printwin.print();
      printwin.close();
    }, 1000);
  };
  handlePrintIndivudualAddress = (customerFirstName, customerLastName, address) => {
    this.addressData = {
      customerFirstName: customerFirstName,
      customerLastName: customerLastName,
      address: address
    };
    this.printTemplate = 2
    setTimeout(() => {
      var printwin = window.open('', '', 'width=800,height=1000');
      printwin.document.write(document.getElementById("TARGET").innerHTML);
      printwin.document.write("<style> @page { size: auto; margin: 0mm; } </style>");
      printwin.print();
      printwin.close();
    }, 1000);
  }
  showList(codes){
    let temp:string = ``;
    for(let code of codes){
      temp+=`<strong class="number-type">${code.key} : </strong> ${code.value}<br>`;
    }

    return temp;
  }
}
