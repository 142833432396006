import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AgentService, CustomService } from 'src/app/_factory';
import { apiurl } from 'src/app/_factory/_api';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { environment } from '../../../../../environments/environment'
import { EmailComponent } from 'src/app/_features/shared/dialog/email/email.component';
import { DialogService } from 'primeng/dynamicdialog';
import { FormGroup, NgForm } from '@angular/forms';
import { RichTextEditor } from '@syncfusion/ej2-angular-richtexteditor';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ActivatedRoute, Router } from '@angular/router';
export interface Item {
  id: string,
  name: string,
  capital: string,
  phone: string,
  currency: string
}
export interface DownLineItem {
  item: Item,
  parent: DownLineItem
}
@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css'],
  providers: [MessageService, PrimeNGConfig]
})
export class CustomerListComponent implements OnInit {
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    uploadUrl: `${environment.baseUrl}/api/Document/p/Upload`,
    upload: (file: File) => {
      return null
    },
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  public insertImageSettings: object = {
    saveUrl: `${environment.baseUrl}/api/Document/p/Upload`
  };

  formInput: FormGroup;
  @Input() productIds;
  @Input() batchData;
  @Input() customerfilters;
  @Input() itemSearchModel;
  @Input() totalEmailProductCount;
  @Input() propsData;
  @Output() emmitEmailSend: EventEmitter<any> = new EventEmitter();
  items = [];
  date: Date[];
  AllowParentSelection = true;
  RestructureWhenChildSameName = false;
  ShowFilter = true;
  Disabled = false;
  FilterPlaceholder = 'Explore Department...';
  MaxDisplayed = 5;
    multipleSelected = [];
    expandDiv = false;
    itemList = {
        data: null,
        totalCount: 0
    };
  customerList = {
    data: null,
    totalCount: 0
  }
  limit = environment.pageLimit;
  page = 0;
  apiPath = apiurl.customer.customer;
  selectedCustomerId;
  searchParam = {
    'query': '',
    'state': '',
    'department': '',
    'startDate': '',
    'endDate': '',
    'itemIsHidden': '',
    "limit": environment.pageLimit.toString(),
    "page": '0'
  }
  master = {
    departmentList: [],
    fromCustomerList: []
  }
  departments = []
  selectedDepartmentIds;
  checkedAll = false;
  checkedAllPage = false;
  customerIds = [];
  singleCustomerIds = [];
  toEmailStr: [] = [];
  removeEmail: any = [];
  ccEmailStr: [] = [];
  bccEmailStr: [] = [];
  formAttribute: any = {
    subject: '',
    toEmail: '',
    fromEmail: '',
    ccEmail: '',
    bccEmail: '',
    body: ''
  };
  submitted = false;
  fullCustomerList = [];
  nextPage = this.page + 1;
  totalCustomersSelected = 0;
  confirmMsgFor = '';
  emailConfig = {};
  cusoterEmailLimit = environment.cusoterEmailLimit;
  customerIdsBackup = [];
  emailCustomers = [];
  authData: any = {};
  isViewMoreAddress = false;
  rightSideBarPosition = 'right';
  selectedAddress = null;
  selectedUploadUrls: any = [];
  public ontoolbarClick(e, rte: RichTextEditor) { // Triggered when toolbar icon clicked 
    if (rte.imageModule.dialogObj) {
      var successEvent = rte.imageModule.uploadObj.success; // Get the source level uploader success event 
      var proxy = rte.imageModule.uploadObj;
      var d = this;
      rte.imageModule.uploadObj.success = function (e: any) {
        (rte.imageModule as any).uploadUrl.url = JSON.parse(e.e.target.responseText).result;
        const imageStr = JSON.parse(e.e.target.responseText).result;
        const altText = (rte.imageModule as any).uploadUrl.altText
        setTimeout(() => {
          d.changeEmailBody(imageStr, altText)
        }, 1000);
        successEvent.call(proxy, e); // Triggered source level success event 
      };
    }
  }


  constructor(private router: Router, private _ActivatedRoute: ActivatedRoute, private _AgentService: AgentService, private messageService: MessageService, private _CustomService: CustomService, public dialogService: DialogService) { }
  ngOnInit() {
    this.formAttribute.subject = this.batchData.subject;
    this.formAttribute.fromEmail = this.batchData.fromEmail;
    if (this.propsData && this.propsData && this.propsData.selectedEmail) {
      this.formAttribute.body = this.propsData.selectedEmail.body || '';
    }
    // this.getSignature();
    this.getDepartmentListData();
    this.getAuthInfo();
    this._ActivatedRoute.queryParams.subscribe(params => {
      if (params) {
        this.searchParam = {
          'query': params.query || params.search || '',
          'state': params.state || '',
          'department': params.department || '',
          'startDate': params.startDate || '',
          'endDate': params.endDate || '',
          'limit': params.limit,
          'page': params.page,
          'itemIsHidden': '',
        }
      }
      if (parseInt(params.page) > 0) {
        this.page = params.page
      }
      if (params.department) {
        let department = params.department.split(',');
        let temp = [];
        department.map((item, index) => {
          temp.push(
            {
              "departmentID": item,
              "departmentName": "",
              "name": ""
            }
          )
        })
        this.multipleSelected = temp;
      } else {
        this.multipleSelected = [];
      }
      this.getCustomerList();
    });
  }
  emailTemplate = (str, alt) => {
    let template = `<table width="100%" border="0" cellspacing="0" cellpadding="0" align="center" >
    <tr>
       <td width="100%" valign="top" bgcolor="#f4f4f4">
          <table width="1080" border="0" cellpadding="0" cellspacing="0" align="center">
             <tr>
              <td class="topimg" align="left" valign="top" ><img  src="${str}" alt="${alt}" border="0" style="display: block; border:0px;" /></td>
             </tr>
          </table>
       </td>
    </tr>
 </table>`;
    return template
  }
  changeEmailBody = (str, alt) => {
    if (this.formAttribute.body.indexOf(str) > -1) {
      var find = `<img src="${str}" class="e-rte-image e-imginline" alt="${alt}" width="auto" height="auto" style="min-width: 0px; max-width: 999px; min-height: 0px;">`
      this.formAttribute.body = this.formAttribute.body.replace(find, this.emailTemplate(str, alt))
    } else {
      setTimeout(() => {
        this.changeEmailBody(str, alt)
      }, 1000);
    }
  }
  getSignature = () => {
    const url = apiurl.account.signature;
    this._AgentService.httpCall(url, 'GET', null, null, null).subscribe((res) => {
      if (res.responseCode == 200) {
        const prefix = '<br/><br/><br/><br/><br/>'
        this.formAttribute.body = prefix + '' + res.result.signature
      } else {
        this.toastMsg('error', 'Error', 'something went wrong')
      }
    })

    }
    expandBtn = () => {
        if (!(this.itemList && this.itemList.totalCount>0)) {
            this.getListData(() => {
                this.expandDiv = !this.expandDiv;
            });
        }
        else
        this.expandDiv = !this.expandDiv;
        
    }
    private getListData(callback) {
       const apiPath = apiurl.products.product;
        const psearchParam = {
            ...this.itemSearchModel,
            'productsIn':this.productIds,
            'page': '0',
            'limit': '100',
        }
        const headers = {
            'page': '0',
            'limit': '100'
         }
        this._AgentService.httpCall(apiPath, 'Get', null, psearchParam, headers).subscribe((res) => {
            if (res.responseCode == 200) {
                this.itemList = res.result;
                if (callback) callback();
            } else {
                this.toastMsg('error', 'Error', 'something went wrong')
            }
        })
    }
    private onPriceChange(value, item, index) {
        if (!isNaN(parseFloat(value))) {
            this.itemList.data[index].custPrice = value
        }
    }
    private GetCustomePriceProductList() {
        var p = [];
        if (this.itemList && this.itemList.data && this.itemList.data.length > 0) {
            let filterP = this.itemList.data.filter(x => x.custPrice)
            for (var pd of filterP) {
                p.push({ Id: pd.itemID, Price: pd.custPrice });
                //p[pd.itemID] = pd.custPrice
            }
            return p;
        }
        return null;
    }
  private process(data): any {
    let result = [];
    result = data.map((item) => {
      return this.toTreeNode(item);
    });
    return result;
  }
  private toTreeNode(node, parent = null) {
    if (node && node.children) {
      node.children.map(item => {
        return this.toTreeNode(item, node);
      });
    }
    return node;
  }
  toastMsg(severity, summary, detail) {
    this.messageService.add({ key: 'tc', severity: severity, summary: summary, detail: detail });
  }
  getAuthInfo = () => {
    this.authData = this._CustomService.getAuthData();
    this.getFromMasterList();
  }
  getCustomerList = () => {
    const searchParam = {
      ...this.searchParam,
      'page': this.page.toString(),
      'limit': this.limit.toString(),
    }
    const headers = searchParam;
    this._AgentService.httpCall(this.apiPath, 'Get', null, searchParam, headers).subscribe((res) => {
      if (res.responseCode == 200) {
        if (this.checkedAll) {
          res.result.data.map((item) => {
            item.checked = true;
          })
        } else {
          res.result.data.map((item) => {
            this.customerIdsBackup.map((ids) => {
              if (item.customerID == ids) {
                item.checked = true
              }
            })
          })
        }
        const fullCustomerList = [...this.fullCustomerList, ...res.result.data];
        const uniqueSet = Array.from(new Set(fullCustomerList.map(a => a.customerID)))
          .map(customerID => {
            return fullCustomerList.find(a => a.customerID === customerID)
          })
        this.fullCustomerList = uniqueSet;
        this.customerList = res.result;
        if (!this.checkedAll) {
          let selectedItems = [];
          this.customerList.data.map((item, index) => {
            if (item.checked) {
              selectedItems.push(item.customerID)
            }
          })
          if (selectedItems.length == this.customerList.data.length) {
            this.checkedAllPage = true
          } else {
            this.checkedAllPage = false
          }
        } else {
          this.postSelectAll();
        }
      } else {
        this.toastMsg('error', 'Error', 'something went wrong')
      }
    }, (errors) => {
      this.toastMsg('error', 'Error', 'Error on fetching Customer list')
    })
  }
  checkDepartment = (deptData) => {
    deptData.map((item, index) => {
      if (this.selectedDepartmentIds.indexOf(item.departmentID) > -1) {
        item.checked = true;
      }
      if (item.children && item.children.length > 0) {
        this.checkDepartment(item.children)
      }
    })
  }
  getDepartmentListData = () => {
    const apiPath = apiurl.departments.department_id.replace('{id}', '01');
    const headers = { 'page': this.page.toString(), 'limit': this.limit.toString() }
    this._AgentService.httpCall(apiPath, 'Get', null, null, headers).subscribe((res) => {
      if (res.responseCode == 200) {
        const data = [];
        data.push(res.result)
        this.master.departmentList = res.result.children;
        this.items = this.process(res.result.children);
      } else {
        console.log('something went wrong')
      }
    })
  }
  getFromMasterList = () => {
    const apiPath = apiurl.email.from_email_list;
    this._AgentService.httpCall(apiPath, 'Get', null, null, {}).subscribe((res) => {
      if (res.responseCode == 200) {
        this.master.fromCustomerList = res.result || [];
        const loggedInEmail = this.authData.userInfo.email;
        if (this.batchData != null && this.batchData.fromEmail != '' && res.result && res.result.indexOf(this.batchData.fromEmail) > -1) {
          this.formAttribute.fromEmail = this.batchData.fromEmail;
        }
        else if (res.result && res.result.indexOf(loggedInEmail) > -1) {
          this.formAttribute.fromEmail = loggedInEmail
        } else {
          this.formAttribute.fromEmail = res.result && res.result[0]
        }
      } else {
        this.master.fromCustomerList = [];
        console.log('something went wrong')
      }
    }, (error) => {
      this.master.fromCustomerList = [];
      console.log('something went wrong')
    })
  }
  handleDatepicker = (value) => {
    const startDate = value[0];
    const endDate = value[1];
    if (!startDate) {
      this.toastMsg('warn', 'Warn', 'Please select start date')
    } else if (!endDate) {
      this.toastMsg('warn', 'Warn', 'Please select start date')
    } else if (startDate && endDate) {
      this.searchParam.startDate = startDate.toJSON();
      this.searchParam.endDate = endDate.toJSON();
      this.handleSearch();
    }
  }
  paginate(event) {
    if (this.page !== event.page) {
      if (this.page > event.page - 1) {
        this.nextPage = event.page - 1;
      } else {
        this.nextPage = event.page + 1;
      }
      this.page = event.page;
      this.getCustomerList()
    }
  }
  storeSerchParam = (callback) => {
    const searchParam = {
      ...this.searchParam,
      'page': this.page.toString(),
      'limit': this.limit.toString(),
    }
    this.router.navigate(['/customer'], { queryParams: searchParam });
    if (callback) {
      callback()
    }
  }
  handleSearch = () => {
    this.page = 0;
    this.storeSerchParam(() => {
      this.getCustomerList()
    })
  }
  showConfirm(summary, detail) {
    this.messageService.clear();
    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: summary, detail: detail });
  }
  onConfirm() {
    this.messageService.clear('c');
    if (this.confirmMsgFor == 'remove-customer') {
      const url = apiurl.customer.customer_id.replace('{id}', this.selectedCustomerId.toString());
      this._AgentService.httpCall(url, 'delete', null, null, {}).subscribe((res) => {
        if (res.responseCode == 200) {
          this.getCustomerList()
          this.toastMsg('success', 'Success', 'Delete Successfully')
        } else {
          this.toastMsg('error', 'Error', 'something went wrong')
        }
      })
    } else if (this.confirmMsgFor == 'send-email') {
      this.httpSendEmail()
    }

  }
  onReject() {
    this.messageService.clear('c');
  }
  handleCustomerDelete = (customerID: number) => {
    this.selectedCustomerId = customerID
    this.confirmMsgFor = 'remove-customer';
    this.showConfirm('Are you sure?', 'Confirm to proceed')
  }
  handleExportSrvice = (customer, type) => {
    let url;
    let ext;
    if (type == 1) {
      url = apiurl.customer.downloadDoc.replace('{category}', customer.customerID)
      ext = 'docx'
    } else if (type == 2) {
      url = apiurl.customer.downloadExcel.replace('{category}', customer.customerID);
      ext = 'xlsx'
    }
    url = environment.production ? url : environment.baseUrl + url;
    var anchor = document.createElement("a");
    const fileName = customer.customerFirstName + ' ' + customer.customerLastName + ' Purchases.' + ext;
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();
  }
  handleCheckedItems(items) {
    var departmentIDs = items.map((item) => { return item.departmentID }).join(',');
    this.searchParam.department = departmentIDs;
    this.handleSearch();
  }
  openEmailDialog = () => {
    this._CustomService.dialogComponentConfig(this.emailConfig, EmailComponent).subscribe(res => {
      if (res != undefined) {

      } else {

      }
    })
  }
  handleEmailDialog = (type, templateType) => {
    this.emailConfig = {
      header: 'Send Email',
      objData: {
        customerIds: type == 1 ? this.customerIds : this.singleCustomerIds,
        templateType: templateType
      },
      width: '80%'
    }
    if (this.totalCustomersSelected > this.cusoterEmailLimit) {
      this.confirmMsgFor = 'send-email';
      this.showConfirm('Are you sure?', `You want to send ${this.totalCustomersSelected} customer`)
    } else {
      this.confirmMsgFor = '';
      this.openEmailDialog()
    }
  }
  handleItemEmail = (id) => {
    this.singleCustomerIds = []
    this.singleCustomerIds.push(id);
    this.handleEmailDialog(2, 1)
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  handleToMail = ($event) => {
    const value = $event.target.value;
    const isValidEmail = this.validateEmail(value);
    if (isValidEmail) {
      let data: any = this.toEmailStr || [];
      data.push(value);
      this.formAttribute.toEmail = data.toString();
      this.toEmailStr = data;
      $event.target.value = ''
    } else {
      this.toastMsg('error', 'Error', 'Not a valid email')
    }
  }
  removeToEmail = (index) => {
    this.toEmailStr.splice(index, 1);
    this.formAttribute.toEmail = this.toEmailStr.toString();
  }
  handleCCMail = ($event) => {
    const value = $event.target.value;
    const isValidEmail = this.validateEmail(value);
    if (isValidEmail) {
      let data: any = this.ccEmailStr || [];
      data.push(value);
      this.formAttribute.ccEmail = data.toString();
      this.ccEmailStr = data;
      $event.target.value = ''
    } else {
      this.toastMsg('error', 'Error', 'Not a valid email')
    }
  }
  removeCCEmail = (index) => {
    this.ccEmailStr.splice(index, 1)
    this.formAttribute.ccEmail = this.ccEmailStr.toString();
  }
  handleBCCMail = ($event) => {
    const value = $event.target.value;
    const isValidEmail = this.validateEmail(value);
    if (isValidEmail) {
      let data: any = this.bccEmailStr || [];
      data.push(value);
      this.formAttribute.bccEmail = data.toString();
      this.bccEmailStr = data;
      $event.target.value = ''
    } else {
      this.toastMsg('error', 'Error', 'Not a valid email')
    }
  }
  removeBCCEmail = (index) => {
    this.bccEmailStr.splice(index, 1)
    this.formAttribute.bccEmail = this.bccEmailStr.toString();
  }
    httpSendEmail = () => {

    const url = apiurl.email.email;
    const data = {
      "subject": this.formAttribute.subject,
      "toEmail": this.formAttribute.toEmail,
      "fromEmail": this.formAttribute.fromEmail,
      "ccEmail": this.formAttribute.ccEmail,
      "bccEmail": this.formAttribute.bccEmail,
      "body": this.formAttribute.body,
      "productIds": this.productIds,
      "customerIds": this.checkedAll ? [] : this.customerIds,
      "searchModel": this.searchParam,
      "itemSearchModel": this.itemSearchModel,
      "batchId": this.batchData.batchId,
      'removeEmail': this.removeEmail,
      'isSelectedProduct': this.productIds.length > 0 ?   false: true,
      'isCustomerSelected': (this.customerIds && this.customerIds.length > 0) || this.checkedAll ? true : false,
      'productsWithPrice': this.GetCustomePriceProductList()
        }
        
    this._AgentService.httpCall(url, 'POST', data, null, null).subscribe((res) => {
      if (res.responseCode == 200) {
        this.toastMsg('success', 'Success', 'Email sent Successfully')
        this.emmitEmailSend.emit(res);
      } else {
        this.toastMsg('error', 'Error', 'something went wrong')
      }
    })
    }

  sendEmail = (myForm: NgForm) => {
    this.submitted = true;
    if (myForm.form.status !== 'INVALID') {
      if (this.totalCustomersSelected > this.cusoterEmailLimit) {
        this.confirmMsgFor = 'send-email';
        this.showConfirm('Are you sure?', `You want to send ${this.totalCustomersSelected} customer`)
      } else {
        this.confirmMsgFor = '';
        this.httpSendEmail()
      }
    } else {
      this.toastMsg('error', 'Form Validation Error', 'Please fill all required fields')
    }
  }

  handleResetSearch = () => {
    this.searchParam = {
      'query': '',
      'state': '',
      'department': '',
      'startDate': '',
      'endDate': '',
      'itemIsHidden': '',
      "limit": environment.pageLimit.toString(),
      "page": '0'
    }
    this.date = null;
    this.page = 0;
    this.multipleSelected = [];
    this.router.navigate(['/customer'], { queryParams: {} });
    this.getCustomerList()
  }

  /* select items */
  postSelectAll = () => {
    this.checkedAllPage = this.checkedAll;
    const customerIdsBackup = []
    const emailCustomers = [];
    this.fullCustomerList.map((list) => {
      if (list.checked) {
        customerIdsBackup.push(list.customerID)
        const customerEmails = list.customerEmail && list.customerEmail.trim() || null;
        const tempEmail = customerEmails && customerEmails.length > 0 && list.customerEmail.split(',');
        if (tempEmail && tempEmail.length > 0) {
          tempEmail.map((emailItem) => {
            const customerEmail = emailItem && emailItem.trim();
            if (customerEmail && customerEmail !== '') {
              let data = {
                customerID: list.customerID,
                customerEmail: customerEmail,
              }
              emailCustomers.push(data)
            }
          })
        }
      }
    })
    this.emailCustomers = emailCustomers;
    this.customerIds = customerIdsBackup;
    this.customerIdsBackup = customerIdsBackup;
    this.totalCustomersSelected = this.checkedAll ? this.customerList.totalCount : 0;
  }
  postCheckBoxSelection = (customerIdsBackup) => {
    this.customerIds = customerIdsBackup;
    this.customerIdsBackup = customerIdsBackup;
    this.totalCustomersSelected = customerIdsBackup.length;
    if (this.totalCustomersSelected == this.customerList.totalCount) {
      this.checkedAll = true;
    } else {
      this.checkedAll = false;
      let temp = 0;
      this.customerList.data.map((item, index) => {
        if (item.checked) {
          temp = temp + 1
        }
      })
      if (this.customerList.data.length == temp) {
        this.checkedAllPage = true
      } else {
        this.checkedAllPage = false
      }
    }
  }
  handleSelect = (item, index) => {
    const customerIdsBackup = [];
    const emailCustomers = [];
    this.fullCustomerList.map((list) => {
      if (list.customerID == item.customerID) {
        list.checked = item.checked
      }
      if (list.checked) {
        customerIdsBackup.push(list.customerID);
        const customerEmails = list.customerEmail && list.customerEmail.trim() || null;
        const tempEmail = customerEmails && customerEmails.length > 0 && list.customerEmail.split(',');
        if (tempEmail && tempEmail.length > 0) {
          tempEmail.map((emailItem) => {
            const customerEmail = emailItem && emailItem.trim();
            if (customerEmail && customerEmail !== '') {
              let data = {
                customerID: list.customerID,
                customerEmail: customerEmail,
              }
              emailCustomers.push(data)
            }
          })
        }
      }
    })
    this.emailCustomers = emailCustomers;
    this.postCheckBoxSelection(customerIdsBackup)

  }

  handleSelectAllPage = () => {
    const customerIdsBackup = [];
    const emailCustomers = [];
    this.customerList && this.customerList.data.map((item, index) => {
      item.checked = this.checkedAllPage
    })
    this.fullCustomerList.map((item) => {
      this.customerList.data.map((customer) => {
        if (item.customerID == customer.customerID) {
          item.checked = customer.checked
        }
      })
    })
    this.fullCustomerList.map((list) => {
      if (list.checked) {
        customerIdsBackup.push(list.customerID)
        const customerEmails = list.customerEmail && list.customerEmail.trim() || null;
        const tempEmail = customerEmails && customerEmails.length > 0 && list.customerEmail.split(',');
        if (tempEmail && tempEmail.length > 0) {
          tempEmail.map((emailItem) => {
            const customerEmail = emailItem && emailItem.trim();
            if (customerEmail && customerEmail !== '') {
              let data = {
                customerID: list.customerID,
                customerEmail: customerEmail,
              }
              emailCustomers.push(data)
            }
          })
        }
      }
    })
    this.emailCustomers = emailCustomers;
    this.postCheckBoxSelection(customerIdsBackup)
  }
  handleSelectAll = () => {
    this.customerList && this.customerList.data.map((item, index) => {
      item.checked = this.checkedAll
    })
    this.fullCustomerList.map((item) => {
      this.customerList.data.map((customer) => {
        if (item.customerID == customer.customerID) {
          item.checked = customer.checked
        }
      })
    })
    this.postSelectAll();
  }
  removeToEmailSelected = (item, index) => {
    const customerIdsBackup = [];
    const emailCustomers = [];
    this.removeEmail.push(item.customerEmail)
    this.fullCustomerList.map((list) => {
      if (list.customerID == item.customerID) {
        list.checked = false
      }
      if (list.checked) {
        customerIdsBackup.push(list.customerID)
        let data = {
          customerID: list.customerID,
          customerEmail: list.customerEmail,

        }
        emailCustomers.push(data)
      }
    })
    this.emailCustomers = emailCustomers;
    this.postCheckBoxSelection(customerIdsBackup)
  }
  handleViewMoreAddress = (addresses) => {
    this.selectedAddress = addresses;
    this.isViewMoreAddress = true;
    this.rightSideBarPosition = 'right'
  }
  handleCloseAddressDialog = () => {
    this.isViewMoreAddress = false;
  }
  showList(codes) {
    let temp: string = ``;
    for (let code of codes) {
      temp += `<strong class="number-type">${code.key} : </strong> ${code.value}<br>`;
    }

    return temp;
  }
  handleEditorChange = (event) => {
    event.replaceAll('http://localhost:4200/', environment.baseUrl + '/')
    }
  showImage = (image) => {
        let temp: string = ``;
        const url = image || 'api/ItemPhotos/DownloadPhoto?id=124303'
        temp += `<img class="tooltip-image" src="/api/ItemPhotos/D/sku/` + image + `" alt="image">`;

        return temp;
    }
}
