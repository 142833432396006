import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgentService, CustomService } from 'src/app/_factory';
import { AuthService } from 'src/app/_factory/auth.service';
import { apiurl } from 'src/app/_factory/_api';
import { Store } from 'src/app/_store/store';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  searchList = null;
  authData: any = {};
  searchParam = {
    search: ''
  }
  limit = environment.pageLimit;
  page = 0;
  notificatonList = null;
  isGlobalSearchBar=false;
  constructor(public store: Store, private _AgentService: AgentService, private _Router: Router, private _AuthenticationService: AuthService, private _CustomService: CustomService) { }
  ngOnInit() {
    this.getAuthInfo();
    // this._AuthenticationService.getAuthEmitter().subscribe((customObject) => {
    //   this.getAuthInfo();
    // });
  }
  handleLogOut = () => {
    this._AuthenticationService.logout()
    this.getAuthInfo();
  }
  getAuthInfo = () => {
    console.log('header')
    this.authData = this._CustomService.getAuthData();
    console.log('this.authData && this.authData.isLoggedIn', this.authData && this.authData.isLoggedIn)
    if (this.authData && this.authData.isLoggedIn==true) {
      console.log('call', this.authData && this.authData.isLoggedIn)
      this.getNotification()
    }
  }
  handleSearch = () => {
    localStorage.setItem('isSearch', 'true')
    this.getSearchAutocomplete()
  }
  getSearchAutocomplete = () => {
    const url = apiurl.global.search
    this._AgentService.httpCall(url, 'Get', null, this.searchParam, null).subscribe((res) => {
      if (res.responseCode == 200) {
        this.searchList = res.result;
      }
    })
  }
  handleSarch = (item) => {
    const type = item.pageName.toLowerCase();
    let url;
    if (type == 'customer') {
      url = '/customer/detail/' + item.id
    } else if (type == 'item') {
      url = '/products'
    } else if (type == 'department') {
      url = '/products'
    }
    this._Router.navigate([url], { queryParams: { search: item.searchMainTitle } })
    const res = {
      query: item.searchMainTitle
    }
    setTimeout(() => {
      if (type == 'customer') {
        window.location = url;
        // this._AuthenticationService.handleCustomerSearch(res);
      } else if (type == 'item') {
        this._AuthenticationService.handleProductSearch(res);
      } else if (type == 'department') {
        this._AuthenticationService.handleDepartmentSearch(res);
      }
      this.isGlobalSearchBar=false
    }, 100);
  }
  getNotification = () => {
    if (this.store.storeNotificationList) {
      this.notificatonList = this.store.storeNotificationList
    } else {
      const searchParam = {
        'page': this.page.toString(),
        'limit': this.limit.toString(),
      }
      const headers = searchParam
      const url = apiurl.dashboard.notification
      this._AgentService.httpCall(url, 'Get', null, searchParam, headers).subscribe((res) => {
        if (res.responseCode == 200) {
          this.notificatonList = res.result;
          this.store.getNotificationAction(res.result)
        } else {
          console.log('error', 'Error', 'something went wrong')
        }
      })
    }
  }
  openSearchBox=()=>{
    this.isGlobalSearchBar=true
  }
  incrementClickOutsideCount=()=>{
   if(this.isGlobalSearchBar){
    this.isGlobalSearchBar=false
   } 
  }
}
