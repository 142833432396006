import { Component, OnInit } from '@angular/core';
import { AgentService, CustomService } from 'src/app/_factory';
import { environment } from '../../environments/environment';
import { apiurl } from '../_factory/_api';
import { Store } from 'src/app/_store/store';
import { AuthService } from '../_factory/auth.service';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  limit = environment.pageLimit;
  page = 0;
  notificatonList = null;
  authData: any = {};
  constructor(private _AgentService: AgentService, private _CustomService: CustomService, public store: Store, private _AuthenticationService: AuthService,) { }
  ngOnInit() {
    this.getNotification(false);
    // this.getAuthInfo();
    // this._AuthenticationService.getAuthEmitter().subscribe((customObject) => {
    //   this.getAuthInfo();
    // });
  }
  getAuthInfo = () => {
    this.authData = this._CustomService.getAuthData();
    console.log('this.authData && this.authData.isLoggedIn', this.authData && this.authData.isLoggedIn)
    if (this.authData && this.authData.isLoggedIn) {
      console.log('call', this.authData && this.authData.isLoggedIn)
      this.getNotification(false);
    }
  }
  httpNotificaton = (isPaginate) => {
    const searchParam = {
      'page': this.page.toString(),
      'limit': this.limit.toString(),
    }
    const headers = searchParam
    const url = apiurl.dashboard.notification
    this._AgentService.httpCall(url, 'Get', null, searchParam, headers).subscribe((res) => {
      if (res.responseCode == 200) {
        this.notificatonList = res.result;
        if (!isPaginate) {
          this.store.getNotificationAction(res.result)
        }
      } else {
        console.log('error', 'Error', 'something went wrong')
      }
    })
  }
  getNotification = (isPaginate) => {
    if (this.store.storeNotificationList && !isPaginate) {
      this.notificatonList = this.store.storeNotificationList
    } else {
      if (!isPaginate) {
        this.httpNotificaton(isPaginate)
      } else {
        this.httpNotificaton(isPaginate)
      }
    }
  }
  paginate(event) {
    if (this.page !== event.page) {
      this.authData = this._CustomService.getAuthData();
      if (this.authData && this.authData.isLoggedIn) {
        this.page = event.page;
        this.getNotification(true)
      }
    }
  }
}
