import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-add-sub-department',
  templateUrl: './add-sub-department.component.html',
  styleUrls: ['./add-sub-department.component.css']
})
export class AddSubDepartmentComponent implements OnInit {
  @Input() parentInfo;
  @Output() emitCloseSubDeptForm: EventEmitter<any> = new EventEmitter();
  submitted = false;
  title:string = 'ADD NEW SUB DEPARTMENT'
  subDeptFormAttribute = {
    departmentID: '',
    departmentName: '',
    departmentIsHidden: true,
    departmentIsEmpty: true,
    departmentSortIndex: 0,
    parent: '',
    parentName: '',
    children: [],
    name: ''
  }
  constructor() { }
    ngOnInit() {
        this.subDeptFormAttribute.departmentName = '';
  }
  handleClose = () => {
    this.submitted = false;
    this.subDeptFormAttribute = {
      departmentID: '',
      departmentName: '',
      departmentIsHidden: true,
      departmentIsEmpty: true,
      departmentSortIndex: 0,
      parent: '',
      parentName: '',
      children: [],
      name: ''
    }
    this.emitCloseSubDeptForm.emit(null);
  }
  handleCancel = () => {
    this.submitted = false;
    this.subDeptFormAttribute = {
      departmentID: '',
      departmentName: '',
      departmentIsHidden: true,
      departmentIsEmpty: true,
      departmentSortIndex: 0,
      parent: '',
      parentName: '',
      children: [],
      name: ''
    }
    this.emitCloseSubDeptForm.emit(null);
  }
  handleSubmit = () => {
    if (this.subDeptFormAttribute.departmentName) {
      this.submitted=false;
      let returnData = {
        departmentID: '',
        departmentName: this.subDeptFormAttribute.departmentName,
        departmentIsHidden: true,
        departmentIsEmpty: true,
        departmentSortIndex: 0,
        parent: this.parentInfo.departmentID || '',
        parentName: this.parentInfo.departmentName || '',
        children: [],
        name: this.subDeptFormAttribute.name
      }
      this.emitCloseSubDeptForm.emit(returnData);
    } else {
      this.submitted=true;
    }
  }
}
