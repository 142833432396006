export const apiurl = {
  customer: {
    'customer': '/api/Customer',
    'customer_id': '/api/Customer/{id}',
    'customerId_Visit': `/api/Customer/{customerId}/Visit`,
    'customerId_Visit_visitId': '/api/Customer/{customerId}/Visit/{visitId}',
    'Visit_visitId': '/api/Customer/Visit/{visitId}',
    'visit_report': `/api/Customer/Visit/Report`,
    'downloadDoc': `/api/Report?category={category}`,
    'downloadExcel': `/api/Report/export?category={category}`,
    'notifications':`/api/Customer/{customerId}/Notification`,
    'Notification_notificationId':`/api/Customer/{customerId}/Notification/{notificationId}`,
    'notes':`/api/Customer/{customerId}/Note`,
    'notes_id': `/api/Customer/{customerId}/Note/{noteId}`,
    'downloadCustomerList': `/api/Customer/Report?department={department}&state={state}&query={query}&startDate={startDate}&endDate={endDate}`,
        'downloadCustomerVisitHistory':'/api/Customer/{customerId}/Visit/Report',
  },
  products: {
    product: `/api/Product`,
    product_id: `/api/Product/{id}`,
   downloadProductList: `/api/Product/Report?department={department}&maxPrice={maxPrice}&query={query}&startDate={startDate}&endDate={endDate}&minPrice={minPrice}&itemIsHidden={itemIsHidden}&itemIsSold={itemIsSold}`,
      Characteristic: `/api/Product/{sku}/Characteristic`,
      Vendors: `/api/Product/GetVendors`,
  },
  departments: {
    department: `/api/Department`,
    department_id: '/api/Department/{id}',
    priceHistory: `/api/Product/{itemId}/pricehistory`,
    auditHistory: `/api/Product/{itemId}/AuditHistory`
  },
  global: {
    global_Country: '/api/Global/Country',
    global_States: '/api/Global/States',
    global_clerks: '/api/Global/Clerks',
    global_actions: '/api/Global/Actions',
    global_shippingMethods: '/api/Global/ShippingMethods',
    stores: "/api/Global/Stores",
      search: "/api/Global/Search",
      cache: "/api/Global/Cache",

  },
  dashboard: {
    dashboard: "/api/Dashboard",
    Analytics: "/api/Dashboard/Analytics",
    notification: `/api/Dashboard/Notification`
  },
  ItemPhotos: {
    uploadPhotos: "/api/ItemPhotos/UploadPhotos",
    list: '/api/ItemPhotos',
    download: `​/api​/ItemPhotos​/DownloadPhoto`
  },
  account: {
    'login': "/api/Account/login",
    'register': "/api/Account",
    'forgot_password':`/api/Account/forgotPassword`,
    'signature':'/api/Account/Signature'
  },
  email: {
    'email': '/api/Email/product',
    'emaillog': '/api/Email/emaillog',
    'emaillog_custmerId': `/api/Email/emaillog/{custmerId}`,
    'emaillog_batch': `/api/Email/emaillog/{customerId}/batch/{batchId}`,
    'email_batch': `/api/Email/batch`,
    'email_batch_customers': `/api/Email/batch/{batchId}/customer`,
    'email_batch_items': `/api/Email/batch/{batchId}/items`,
    'from_email_list':`/api/Email/fromEmail`,
    'email_setting':`/api/Email/setting`
  },
  reports: {
      sale: `/api/Report/sales`,
      checkRequest: `/api/Report/product/Inventory/request`,
      DownLoadItem: `/api/Report/product/Inventory/export`,
  },
  clearks: {
    cleark: `/api/Clerk`,
    cleark_id: `/api/Clerk/{clerkID}`
    },
    additions: {
        items: `/api/Addition/{additionType}/items`,
        deleteItem:'/api/Addition/{id}',
        SaveData: `/api/Addition/{additionType}/items`,
        searchItemSku: "/api/Addition/SearchSku",
        searchItemSkuDepartmentwise: "/api/Addition/SearchSkuDepartmentWise",
        BrowseAllItem: `/api/Addition/{additionType}/browseallitem`,
        BrowseAllCollectionDepartment: `/api/Addition/BrowseAllCollectionDepartment`,
        deleteallcollectiondepart: '/api/Addition/{id}/Department',
        SaveBrowseallcollectionData: `/api/Addition/SaveBrowseAllCollectionDepart`,
        SaveOrderwiseItem: `/api/Addition/{additionType}/SaveOrderwise`,
        SaveDepartmentImages: `/api/Addition/SaveDepartmentImage`,
        GetDepartmentImages: `/api/Addition/GetDepartmentImages`,
        GetDepartmentImagesDetails: `/api/Addition/{Id}/GetDepartmentImages`,
        deleteDepartmentImagesItem: '/api/Addition/{id}/DeleteDepartmentImages',
        UploadDepartmentImages: `/api/Addition/UploadDepartmentImage`,
        GetDepartmentWiseItem: `/api/Addition/dept/{id}/Items`,
        SaveOrderofItem: `/api/Addition/SaveOrderofItem`,
        GetOrderItems: `/api/Addition/GetOrderItems`,
        searchItemSkuDepartmentwiseForOrdering: "/api/Addition/SearchSkuDepartmentWiseForOrdering",
    },
    itemSales: {
        itemSale:  `/api/ItemSale`,
        itemSaleWithId: `/api/ItemSale/{itemId}`,
        ShowSale: `/api/ItemSale/showSales`,
    },
  states: {
    state: `/api/State`,
    stateAbbreviation: `​/api​/State​/{stateAbbreviation}`
  },
  documents: {
    list: `/api/Document`,
    upload: `/api/Document/Upload`,
    id: `/api/Document/{id}`,
   download: `​/api/Document/Download`,
   uploadDepartmentImage: '/api/Document/p/d/Upload',
   uploadDepartmentPlaceHolderImage: '/api/Document/p/d/t/Upload',
  },
  characteristics: {
    characteristic: `/api/Characteristic`,
    id: `/api/Characteristic/{id}`
  },
  productType: {
    ProductType: `/api/ProductType`,
      id: `/api/ProductType/{id}`,
      saveCSV:`/api/ProductType/SaveBulkItems`
  }
}