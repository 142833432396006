import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CustomService } from 'src/app/_factory';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor(private _CustomService: CustomService) { }
  activeLoader: Subject<boolean> = this._CustomService.activeLoader;
  ngOnInit(): void {
  }

}
