import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { CustomService } from './custom.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  refCode;
  constructor(
    private router: Router, private _CustomService: CustomService
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser: any = this._CustomService.getAuthData();
    let returnUrl = window.location.href;
    localStorage.setItem('returnurl', returnUrl);
    if (currentUser && currentUser.isLoggedIn) {
      return true;
    }
    // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    this.router.navigate(['/auth/login'], { queryParams: route.queryParams })
    return false;
  }
}