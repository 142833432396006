import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpBackend, HttpClient} from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment'
declare global {
  interface Window { dataLayer: any[]; }
}
@Injectable({
  providedIn: 'root'
})
export class AgentService {
    constructor(private _HttpClient: HttpClient, private _AuthService: AuthService, private httpBackend: HttpBackend) { }
  activeLoader = new Subject<boolean>();
  show() {
    this.activeLoader.next(true);
  }
  hide() {
    this.activeLoader.next(false);
  }
  isMobile() {
    if (sessionStorage.desktop)
      return false;
    else if (localStorage.mobile)
      return true;
    var mobile = ['iphone', 'ipad', 'android', 'blackberry', 'nokia', 'opera mini', 'windows mobile', 'windows phone', 'iemobile'];
    for (var i in mobile) if (navigator.userAgent.toLowerCase().indexOf(mobile[i].toLowerCase()) > 0) return true;
    return false;
  };
  apiUrlName() {
    let url = ''
    return url;
  }
    httpCall(url: string, method: string, data: any, params: any, headers: any) {
       
    const httmMetho = method.toLocaleLowerCase();
    let apiUrl = environment.baseUrl + url;
    switch (httmMetho) {
      case 'post':
            return this._HttpClient.post<any>(apiUrl, data, { 'headers': {}, 'params': params })
            break;
        case 'fileupload':
            const newHttpClient = new HttpClient(this.httpBackend);
            return newHttpClient.post<any>(apiUrl, data, { 'headers': { ...headers }, 'params': params })
            break;
      case 'put':
        return this._HttpClient.put<any>(apiUrl, data, { 'headers': {}, 'params': params })
        break;
      case 'delete':
        return this._HttpClient.delete<any>(apiUrl, { 'headers': {}, 'params': params })
        break;
      case 'get':
        switch (headers) {
          case null:
            return this._HttpClient.get<any[]>(apiUrl, { 'headers': {}, 'params': params });
            break;
          default:
            return this._HttpClient.get<any[]>(apiUrl, { headers: { ...headers }, 'params': params })
        }
        break;
      default:
        return
    }
  }
}
