import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './_factory/auth-guard.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NotificationComponent } from './notification/notification.component';
import { ErrorComponent } from './components/error/error.component';
import { SignatureComponent } from './signature/signature.component';
const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuardService]},
  { path: 'all-notificatiions', component: NotificationComponent,canActivate: [AuthGuardService]},
  { path: 'signature', component: SignatureComponent,canActivate: [AuthGuardService]},
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./_features/auth/auth.module').then(mod => mod.AuthModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('./_features/customer/customer.module').then(mod => mod.CustomerModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./_features/product/product.module').then(mod => mod.ProductModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./_features/report/report.module').then(mod => mod.ReportModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./_features/setting/setting.module').then(mod => mod.SettingModule)
  },
  {
    path: 'email',
    loadChildren: () => import('./_features/email/email.module').then(mod => mod.EmailModule)
  },
  { path: '**', component: ErrorComponent, pathMatch: 'full' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
