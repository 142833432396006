import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AgentService, CustomService } from 'src/app/_factory';
import { apiurl } from 'src/app/_factory/_api';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { environment } from '../../../../../environments/environment';
import { Store } from '../../../../_store/store';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, NgForm } from '@angular/forms';
import { RichTextEditor } from '@syncfusion/ej2-angular-richtexteditor';
export interface Item {
    id: string,
    name: string,
    capital: string,
    phone: string,
    currency: string
}
export interface DownLineItem {
    item: Item,
    parent: DownLineItem
}
@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
    providers: [MessageService, PrimeNGConfig]
})
export class ProductListComponent implements OnInit {
    @Input() customerId;
    @Input() batchData;
    @Input() customerfilters;
    @Input() totalCustomersSelected;
    @Input() emailCustomers;
    @Input() propsData;
    @Output() emmitEmailSend: EventEmitter<any> = new EventEmitter();
    public insertImageSettings: object = {
        saveUrl: `${environment.baseUrl}/api/Document/p/Upload`
    };
    formInput: FormGroup;
    date: Date[];
    toEmailStr: [] = [];
    removeEmail: any = [];
    ccEmailStr: [] = [];
    bccEmailStr: [] = [];
    formAttribute: any = {
        subject: '',
        toEmail: '',
        fromEmail: '',
        ccEmail: '',
        bccEmail: '',
        body: ``,
    };

   
    submitted = false;
    items = [];
    AllowParentSelection = true;
    RestructureWhenChildSameName = false;
    ShowFilter = true;
    Disabled = false;
    FilterPlaceholder = 'Explore Department...';
    MaxDisplayed = 5;
    multipleSelected = [];
    listData = {
        data: null,
        totalCount: 0
    }
    master = {
        departmentList: [],
        fromCustomerList: []
    }
    limit = environment.pageLimit;
    priceHisoty: null;
    priceHistoryDialogVisisble = false;
    priceHistoryDialogPosition: string = 'right';
    priceHistorySelectedId = null;
    departments = [];
    page = 0;
    apiPath = apiurl.products.product;
    selectedCustomerId;
    searchParam = {
        'query': '',
        'Department': '',
        'Sku': '',
        'minPrice': '',
        'maxPrice': '',
        "startDate": "",
        "endDate": "",
        'sortBy': '',
        'sortDirection': '',
        'itemIsHidden': '',
        'itemIsSold': '',
        "limit": environment.pageLimit.toString(),
        "page": '0'
    }
    selectedProductIds = [];
    selectedItemIds = [];
    checkedAll = false;
    fullListData = [];
    nextPage = this.page + 1;
    totalEmailProductCount = 0;
    productEmailLimit = 0;
    emailSetting = {}
    authData: any = {};
  
    public ontoolbarClick(e, rte: RichTextEditor) { // Triggered when toolbar icon clicked 
       
        if (rte.imageModule.dialogObj) {
            var successEvent = rte.imageModule.uploadObj.success; // Get the source level uploader success event 
            var proxy = rte.imageModule.uploadObj;
            var d = this;
            rte.imageModule.uploadObj.success = function (e: any) {
                (rte.imageModule as any).uploadUrl.url = JSON.parse(e.e.target.responseText).result;
                const imageStr = JSON.parse(e.e.target.responseText).result;
                const altText = (rte.imageModule as any).uploadUrl.altText
                setTimeout(() => {
                    d.changeEmailBody(imageStr, altText)
                }, 1000);
                successEvent.call(proxy, e); // Triggered source level success event 
            };
        }
    }
    constructor(private router: Router, private _AgentService: AgentService, private messageService: MessageService, public store: Store, private _CustomService: CustomService, private _ActivatedRoute: ActivatedRoute) { }
    ngOnInit(): void {
       
        // this.getSignature()
        this.getEmailSetting();
        this.getDepartmentListData();
        this.getAuthInfo();
        this._ActivatedRoute.queryParams.subscribe(params => {
            if (params) {
                this.searchParam = {
                    'query': params.query || params.search || '',
                    'Department': params.Department || '',
                    'Sku': params.Sku || '',
                    'minPrice': params.minPrice || '',
                    'maxPrice': params.maxPrice || '',
                    "sortBy": params.sortBy || '',
                    "startDate": params.startDate || '',
                    "endDate": params.endDate || '',
                    "sortDirection": params.sortDirection || '',
                    "itemIsHidden": params.itemIsHidden || '',
                    "itemIsSold": params.itemIsSold || '',
                    'page': params.page,
                    'limit': params.page,
                }
            }
            if (params.search) {
                this.searchParam.query = params.search;
            }
            if (parseInt(params.page) > 0) {
                this.page = params.page
            }
            if (params.Department) {
                let department = params.Department.split(',');
                let temp = [];
                department.map((item, index) => {
                    temp.push(
                        {
                            "departmentID": item,
                            "departmentName": "",
                            "name": ""
                        }
                    )
                })
                this.multipleSelected = temp;
            } else {
                this.multipleSelected = [];
            }
            this.getListData();
        });
        if (this.propsData && this.propsData && this.propsData.selectedEmail) {
            this.formAttribute.body = this.propsData.selectedEmail.body || '';
        }
    }
    emailTemplate = (str, alt) => {
        let template = `<table width="100%" border="0" cellspacing="0" cellpadding="0" align="center" >
        <tr>
           <td width="100%" valign="top" bgcolor="#f4f4f4">
              <table width="1080" border="0" cellpadding="0" cellspacing="0" align="center">
                 <tr>
                  <td class="topimg" align="left" valign="top" ><img  src="${str}" alt="${alt}" border="0" style="display: block; border:0px;" /></td>
                 </tr>
              </table>
           </td>
        </tr>
     </table>`;
        return template
    }
    changeEmailBody = (str, alt) => {
        if (this.formAttribute.body.indexOf(str) > -1) {
            var find = `<img src="${str}" class="e-rte-image e-imginline" alt="${alt}" width="auto" height="auto" style="min-width: 0px; max-width: 999px; min-height: 0px;">`
            this.formAttribute.body = this.formAttribute.body.replace(find, this.emailTemplate(str, alt))
        } else {
            setTimeout(() => {
                this.changeEmailBody(str, alt)
            }, 1000);
        }
    }
    getSignature = () => {
        const url = apiurl.account.signature;
        this._AgentService.httpCall(url, 'GET', null, null, null).subscribe((res) => {
            if (res.responseCode == 200) {
                const prefix = '<br/><br/><br/><br/><br/>'
                this.formAttribute.body = prefix + '' + res.result.signature
            } else {
                this.toastMsg('error', 'Error', 'something went wrong')
            }
        })
    }
    private process(data): any {
        let result = [];
        result = data.map((item) => {
            return this.toTreeNode(item);
        });
        return result;
    }
    private toTreeNode(node, parent = null) {
        if (node && node.children) {
            node.children.map(item => {
                return this.toTreeNode(item, node);
            });
        }
        return node;
    }
    toastMsg(severity, summary, detail) {
        this.messageService.add({ key: 'tc', severity: severity, summary: summary, detail: detail });
    }
    getListData = () => {
        const searchParam = {
            ...this.searchParam,
            'page': this.page.toString(),
            'limit': this.limit.toString(),
        }
        const headers = searchParam
        this._AgentService.httpCall(this.apiPath, 'Get', null, searchParam, headers).subscribe((res) => {
            if (res.responseCode == 200) {
                res.result.data.map((item, index) => {
                    this.selectedProductIds.map((ids, i) => {
                        if (item.itemID == ids) {
                            item.checked = true
                        }
                    })
                })
                if (this.page == 0) {
                    this.fullListData = res.result.data
                } else {
                    if (this.nextPage > this.page) {
                        this.fullListData = [...this.fullListData, ...res.result.data]
                    } else {
                        this.fullListData = this.fullListData.reverse().slice(this.limit).reverse()
                    }
                }
                this.listData = res.result;
                if (this.checkedAll) {
                    this.handleSelectAll(2)
                }
            } else {
                this.toastMsg('error', 'Error', 'something went wrong')
            }
        })
    }
    getEmailSetting = () => {
        const apiPath = apiurl.email.email_setting;
        this._AgentService.httpCall(apiPath, 'Get', null, null, {}).subscribe((res) => {
            if (res.responseCode == 200) {
                this.emailSetting = res.result;
                this.productEmailLimit = res.result.protectProductsInSendEmail || 0;
            } else {
                console.log('something went wrong')
            }
        }, (error) => {
            console.log('Error on getting email setting', error)
        })
    }
    getDepartmentListData = () => {
        const apiPath = apiurl.departments.department_id.replace('{id}', '01');
        const headers = { 'page': this.page.toString(), 'limit': this.limit.toString() }
        this._AgentService.httpCall(apiPath, 'Get', null, null, headers).subscribe((res) => {
            if (res.responseCode == 200) {
                const data = [];
                data.push(res.result)
                this.master.departmentList = res.result.children;
                this.store.departmentDataAction(res.result.children);
                this.items = this.process(res.result.children);
            } else {
                console.log('something went wrong')
            }
        })
    }
    getFromMasterList = () => {
        const apiPath = apiurl.email.from_email_list;
        this._AgentService.httpCall(apiPath, 'Get', null, null, {}).subscribe((res) => {
            if (res.responseCode == 200) {
                this.master.fromCustomerList = res.result || [];
                const loggedInEmail = this.authData.userInfo.email;
                if (this.batchData != null && this.batchData.fromEmail != '' && res.result && res.result.indexOf(loggedInEmail) > -1) {
                    this.formAttribute.fromEmail = this.batchData.fromEmail;
                }
                else if (res.result && res.result.indexOf(loggedInEmail) > -1) {
                    this.formAttribute.fromEmail = loggedInEmail
                } else {
                    this.formAttribute.fromEmail = res.result && res.result[0]
                }

            } else {
                this.master.fromCustomerList = [];
                console.log('something went wrong')
            }
        }, (error) => {
            this.master.fromCustomerList = [];
        })
    }
    handleCheckedItems(items) {
        var departmentIDs = items.map((item) => { return item.departmentID }).join(',');
        this.searchParam.Department = departmentIDs;
        // this.departments = departments;
        this.handleSearch();
    }
    storeSerchParam = (callback) => {
        const searchParam = {
            ...this.searchParam,
            'page': this.page.toString(),
            'limit': this.limit.toString(),
        }
        this.router.navigate(['/products'], { queryParams: searchParam });
        if (callback) {
            callback()
        }
    }
    handleSearch = () => {
        this.page = 0;
        const searchParam = {
            ...this.searchParam,
            'page': this.page.toString(),
            'limit': this.limit.toString(),
        }
        this.storeSerchParam(() => {
            this.getListData()
        })
    }
    handleResetSearch = () => {
        this.searchParam = {
            'query': '',
            'Department': '',
            'Sku': '',
            'minPrice': '',
            'maxPrice': '',
            'sortBy': '',
            "startDate": "",
            "endDate": "",
            'sortDirection': '',
            'itemIsHidden': '',
            'itemIsSold': '',
            'page': '0',
            'limit': environment.pageLimit.toString(),
        }
        this.date = null;
        this.page = 0;
        this.multipleSelected = [];
        this.router.navigate(['/products'], { queryParams: {} });
        this.getListData()
    }
    paginate(event) {
        if (this.page !== event.page) {
            if (this.page > event.page - 1) {
                this.nextPage = event.page - 1;
            } else {
                this.nextPage = event.page + 1;
            }
            this.page = event.page;
            this.storeSerchParam(() => {
                this.getListData()
            })
        }
    }
    getAuthInfo = () => {
        this.authData = this._CustomService.getAuthData();
        this.getFromMasterList();
    }
    handleDatepicker = (value) => {
        const startDate = value[0];
        const endDate = value[1];
        if (startDate && endDate) {
            this.searchParam.startDate = startDate.toJSON();
            this.searchParam.endDate = endDate.toJSON();
            this.handleSearch();
        }
    }
    handleFilterForm(value) {
        this.searchParam.minPrice = value.minPrice;
        this.searchParam.maxPrice = value.maxPrice;
        this.storeSerchParam(() => {
            this.getListData()
        })
    }
    getSelectedIds = (callback) => {
        this.selectedProductIds = [];
        this.listData && this.listData.data.map((item, index) => {
            if (item.checked) {
                this.selectedProductIds.push(item.itemID)
            }
        })
        if (callback) {
            callback()
        }
    }

    

    handleSelect = (item, index) => {
        
        if (item.checked) {
            this.selectedProductIds.push(item.itemID)
            
        } else {
            this.selectedProductIds = this.selectedProductIds.filter(id => id !== item.itemID)
        }
        this.totalEmailProductCount = this.selectedProductIds.length;
        if (this.selectedProductIds.length == this.listData.data) {
            this.checkedAll = true
        } else {
            this.checkedAll = false
        }
    }
    handleSelectAll = (type) => {
        if (type == 1) {
            this.checkedAll = !this.checkedAll;
        }
        this.fullListData && this.fullListData.map((item, index) => {
            item.checked = this.checkedAll
        })
        this.listData && this.listData.data.map((item, index) => {
            item.checked = this.checkedAll
        })
        this.getSelectedIds(() => {
            this.totalEmailProductCount = this.checkedAll ? this.listData.totalCount : 0;
        })
    }
    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    handleToMail = ($event) => {
        const value = $event.target.value;
        const isValidEmail = this.validateEmail(value);
        if (isValidEmail) {
            let data: any = this.toEmailStr || [];
            data.push(value);
            this.formAttribute.toEmail = data.toString();
            this.toEmailStr = data;
            $event.target.value = ''
        } else {
            this.toastMsg('error', 'Error', 'Not a valid email')
        }
    }
    removeToEmail = (index) => {
        this.toEmailStr.splice(index, 1);
        this.formAttribute.toEmail = this.toEmailStr.toString();
    }
    handleCCMail = ($event) => {
        const value = $event.target.value;
        const isValidEmail = this.validateEmail(value);
        if (isValidEmail) {
            let data: any = this.ccEmailStr || [];
            data.push(value);
            this.formAttribute.ccEmail = data.toString();
            this.ccEmailStr = data;
            $event.target.value = ''
        } else {
            this.toastMsg('error', 'Error', 'Not a valid email')
        }
    }
    removeCCEmail = (index) => {
        this.ccEmailStr.splice(index, 1)
        this.formAttribute.ccEmail = this.ccEmailStr.toString();
    }
    handleBCCMail = ($event) => {
        const value = $event.target.value;
        const isValidEmail = this.validateEmail(value);
        if (isValidEmail) {
            let data: any = this.bccEmailStr || [];
            data.push(value);
            this.formAttribute.bccEmail = data.toString();
            this.bccEmailStr = data;
            $event.target.value = ''
        } else {
            this.toastMsg('error', 'Error', 'Not a valid email')
        }
    }
    removeBCCEmail = (index) => {
        this.bccEmailStr.splice(index, 1)
        this.formAttribute.bccEmail = this.bccEmailStr.toString();
    }
    sendEmail = (myForm: NgForm) => {
        if (this.totalEmailProductCount > this.productEmailLimit) {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: `You cannot send more than ${this.productEmailLimit} products in one email` });
        } else {
            this.submitted = true;
            if (myForm.form.status !== 'INVALID') {
                const url = apiurl.email.email;
                const itemSearchModel = {
                    ...this.searchParam
                }
                const data = {
                    "subject": this.formAttribute.subject,
                    "toEmail": this.formAttribute.toEmail,
                    "fromEmail": this.formAttribute.fromEmail,
                    "ccEmail": this.formAttribute.ccEmail,
                    "bccEmail": this.formAttribute.bccEmail,
                    "body": this.formAttribute.body,
                    "productIds": this.checkedAll ? [] : this.selectedProductIds,
                    "customerIds": this.customerId,
                    "searchModel": this.customerfilters,
                    "itemSearchModel": itemSearchModel,
                    "batchId": this.batchData.batchId,
                    'removeEmail': this.removeEmail,
                    'isSelectedProduct': this.selectedProductIds.length > 0 || this.checkedAll ? true : false,
                    'isCustomerSelected': (this.customerId && this.customerId.length > 0) || (this.totalCustomersSelected > 0) ? true : false
                }
                this._AgentService.httpCall(url, 'POST', data, null, null).subscribe((res) => {
                    if (res.responseCode == 200) {
                        this.toastMsg('success', 'Success', 'Email sent Successfully')
                        this.emmitEmailSend.emit(res);
                    } else {
                        this.toastMsg('error', 'Error', 'something went wrong')
                    }
                })
            } else {
                this.toastMsg('error', 'Form Validation Error', 'Please fill all required fields')
            }
        }
    }
    removeToEmailSelected = (item, index) => {
        this.emailCustomers.splice(index, 1);
        item.checked = false;
        this.removeEmail.push(item.customerEmail)
    }
    handleSortby = () => {
        this.searchParam.sortBy = "itemPrice"
        if (!this.searchParam.sortDirection) {
            this.searchParam.sortDirection = 'asc'
        }
        this.handleSearch()
    }
}
