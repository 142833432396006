import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css']
})
export class DoughnutChartComponent implements OnInit {
  @Input() chartData: any;
  @Input() color: any;
  id= "DonutGraph" + Math.floor(Math.random() * 100);
  highcharts = Highcharts;
  data = {
    series: [
      {
        data: [],
      },
    ],
  };
  public options: any = {
    chart: {
      type: "pie",
      options3d: {
        enabled: true,
        alpha: 45
      }
    },
    title: {
      verticalAlign: "middle",
      floating: true,
      style: {
        display: "none",
      },
    },
    plotOptions: {
      pie: {
        showInLegend: false,
        dataLabels: {
          enabled: false,
        },
        innerSize: "50%",
      },
    },
    series: this.data.series,
  }
  constructor() { }
  ngOnInit() {
    this.createChartData(() => {
      Highcharts.chart('container', this.options);
    })
  }
  createChartData = (callback) => {
    const tempData = [];
    this.chartData.map((item, index) => {
      let chatItem = {
        name: item.departmentName,
        y: item.totalSale,
        textColor: item.textColor || this.color[index],
        color: item.color || this.color[index],
      }
      tempData.push(chatItem)
    })
    this.data.series[0].data = tempData;
    if (callback) {
      callback()
    }
  }
}
