import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AgentService, CustomService } from 'src/app/_factory';
import { AuthService } from 'src/app/_factory/auth.service';
import { apiurl } from 'src/app/_factory/_api';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [MessageService]
})
export class DashboardComponent implements OnInit {
  color = ["#FF8C5A", "#34C38F", "#F46A6A"]
  dashboardData = null;
  localImageBaseUrl = './../../../assets/';
  dashboardAnalytics = null;
  activeTab = 1;
  authData: any = {};
  constructor(private _AgentService: AgentService, private messageService: MessageService, private _AuthenticationService: AuthService, private _ActivatedRoute: ActivatedRoute, private _CustomService: CustomService) { }
  ngOnInit() {
    this.getDashboardData();
    this.getDashboardAnalytics()
    // this.getAuthInfo();
    // this._AuthenticationService.getAuthEmitter().subscribe((customObject) => {
    //   this.getAuthInfo();
    // });
  }
  getAuthInfo = () => {
    this.authData = this._CustomService.getAuthData();
    if (this.authData && this.authData.isLoggedIn) {
      this.getDashboardData();
      this.getDashboardAnalytics()
    }
  }
  getDashboardData = () => {
    const apiPath = apiurl.dashboard.dashboard;
    this._AgentService.httpCall(apiPath, 'Get', null, null, {}).subscribe((res) => {
      if (res.responseCode == 200) {
        this.dashboardData = res.result;
      } else {
        console.log('something went wrong')
      }
    })

  }
  getDashboardAnalytics = () => {
    const apiPath = apiurl.dashboard.Analytics;
    this._AgentService.httpCall(apiPath, 'Get', null, null, {}).subscribe((res) => {
      if (res.responseCode == 200) {
        this.dashboardAnalytics = res.result;
      } else {
        console.log('something went wrong')
      }
    })
  }
  renderNameAvtar = (nameStr) => {
    let returnName = '';
    const nameArray = nameStr.split(' ') || [];
    if (nameArray && nameArray.length > 0) {
      returnName = nameArray[0].charAt(0)
    }
    if (nameArray && nameArray.length > 1) {
      returnName = returnName + '' + nameArray[1].charAt(0)
    }
    return returnName;
  }
  handleActiveTab = (index) => {
    this.activeTab = index
  }
  CreateClass = (value, type) => {
    let classStr = '';
    if (type == 'str') {
      if (value && value.indexOf('+') > -1) {
        classStr = 'percent'
      } else if (value && value.indexOf('-') > -1) {
        classStr = 'percent-n'
      } else {
        classStr = 'percent-m'
      }

      return classStr
    } else if (type == 'num') {
      if (value && value > 0) {
        classStr = 'percent'
      } else if (value && value < 0) {
        classStr = 'percent-n'
      } else {
        classStr = 'percent-m'
      }
      return classStr
    }
  }
}
