import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgentService, CustomService } from './_factory';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as interceptor from './_interceptor';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ErrorComponent } from './components/error/error.component';
import { HeaderComponent, FooterComponent, SideVerticalNavComponent } from './components/HeaderFooter';
import { Store } from './_store/store';
import { NgPrimeModule } from './_factory/prime.module';
import { LoaderComponent } from './components/loader/loader.component';
import { ChartsModule } from 'ng2-charts';
import { DoughnutChartComponent } from './components/chart/doughnut-chart/doughnut-chart.component';
import { DashoardListComponent } from './components/dashoard-list/dashoard-list.component';
import { ExpandMode, NgxTreeSelectModule } from 'ngx-tree-select';
import {DialogService} from 'primeng/dynamicdialog';
import { SharedModule } from './_features/shared/shared.module';
import { NotificationComponent } from './notification/notification.component';
import { HttpErrorInterceptor } from './_interceptor';
import { NgxMaskModule } from 'ngx-mask';
import { SignatureComponent } from './signature/signature.component';
import { PendingChangesGuard } from './_factory/pendingchanges.guard';



@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    HeaderComponent,
    FooterComponent,
    SideVerticalNavComponent,
    DashboardComponent,
    LoaderComponent,
    DoughnutChartComponent,
    DashoardListComponent,
    NotificationComponent,
    SignatureComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgPrimeModule,
    HttpClientModule,
    AppRoutingModule,
    ChartsModule,
    SharedModule,
    NgxMaskModule.forRoot({
      showMaskTyped : true,
      // clearIfNotMatch : true
    }),
    NgxTreeSelectModule.forRoot({
      allowFilter: true,
      filterPlaceholder: 'Type your filter here...',
      maxVisibleItemCount: 5,
      idField: 'id',
      textField: 'name',
      childrenField: 'children',
      allowParentSelection: true,
      expandMode: ExpandMode.Selection
    }),
  ],
  providers: [
    Store,
    AgentService,
    CustomService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: interceptor.HeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    DialogService,
    PendingChangesGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
