import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailComponent } from './dialog/email/email.component';
import { ConfirmComponent } from './dialog/confirm/confirm.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { ProductListComponent } from './product/product-list/product-list.component';
import { NgPrimeModule } from 'src/app/_factory/prime.module';
import { ExpandMode, NgxTreeSelectModule } from 'ngx-tree-select';
import { AddDepartmentComponent } from './add-department/add-department.component';
import { AddSubDepartmentComponent } from './add-sub-department/add-sub-department.component';
import { DocumentComponent } from './document/document.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ClickOutsideDirective } from 'src/app/_common/_directory/click-outside.directive';
@NgModule({
  declarations: [EmailComponent, ConfirmComponent, CustomerListComponent, ProductListComponent, AddDepartmentComponent, AddSubDepartmentComponent, DocumentComponent, BreadcrumbComponent, ClickOutsideDirective],
  imports: [
    CommonModule, NgPrimeModule,
    NgxTreeSelectModule.forRoot({
      allowFilter: true,
      filterPlaceholder: 'Type your filter here...',
      maxVisibleItemCount: 5,
      idField: 'id',
      textField: 'name',
      childrenField: 'children',
      allowParentSelection: true,
      expandMode: ExpandMode.Selection
    }),
  ],
  exports: [
    EmailComponent, ConfirmComponent, CustomerListComponent, ProductListComponent, AddDepartmentComponent, DocumentComponent, BreadcrumbComponent, ClickOutsideDirective
  ],
  entryComponents: [
      EmailComponent, ConfirmComponent, ProductListComponent
      
  ],
  providers: []
})
export class SharedModule { }
