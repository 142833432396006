import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../_models/User';
import { Router } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class AuthService {
  @Output() fireIsLoggedIn: EventEmitter<any> = new EventEmitter<any>();
  @Output() fireProductSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() fireCustomerSearch: EventEmitter<any> = new EventEmitter<any>();
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private currentProductSubject: BehaviorSubject<User>;
  public currentProductSearch: Observable<User>;

  private currentCustomerSubject: BehaviorSubject<User>;
  public currentCustomerSearch: Observable<User>;

  constructor(private _Router: Router) {
    let tempUserInfo: any;
    tempUserInfo = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')))

    let productSearchInfo: any;
    productSearchInfo = new BehaviorSubject<any>('')

    let customerSearchInfo: any;
    customerSearchInfo = new BehaviorSubject<any>('')


    this.currentUserSubject = tempUserInfo;
    this.currentUser = this.currentUserSubject.asObservable();

    /* Product Search */
    this.currentProductSubject = productSearchInfo;
    this.currentProductSearch = this.currentProductSubject.asObservable();


    /* Customer Search */
    this.currentCustomerSubject = customerSearchInfo;
    this.currentCustomerSearch = this.currentCustomerSubject.asObservable();
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  loginSuccess(res: any, data: any) {
    let user;
    user = {
      ...res['result']
    }
    localStorage.setItem('currentUser', JSON.stringify(user));
    setTimeout(() => {
      this.currentUserSubject.next(data);
      this.fireIsLoggedIn.emit()
      let returnUrl = localStorage.getItem('returnurl') || '/';
      window.location.href = returnUrl;
      localStorage.removeItem('returnUrl')
    }, 300)
  }


  /* product search */
  public get currentProductSearchValue(): User {
    return this.currentProductSubject.value;
  }
  handleProductSearch(res: any) {
    this.currentProductSubject.next(res);
    this.fireProductSearch.emit()
  }
  getProductEmitter() {
    return this.fireProductSearch;
  }


  /* customer Search */
  public get currentCustomerSearchValue(): User {
    return this.currentProductSubject.value;
  }
  handleCustomerSearch(res: any) {
    this.currentUserSubject.next(res);
    this.fireCustomerSearch.emit()
  }
  getCustomerEmitter() {
    return this.fireCustomerSearch;
  }


  /* Department Search */

  handleDepartmentSearch(res: any) {
    this.currentUserSubject.next(res);
    this.fireIsLoggedIn.emit()
  }
  getAuthEmitter() {
    return this.fireIsLoggedIn;
  }
  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.currentUserSubject.next(null);
    this.fireIsLoggedIn.emit()
    this._Router.navigate(['/auth/login']);
  }
}
