import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/_store/store';

@Component({
  selector: 'app-side-vertical-nav',
  templateUrl: './side-vertical-nav.component.html',
  styleUrls: ['./side-vertical-nav.component.scss']
})
export class SideVerticalNavComponent implements OnInit {

    constructor(public store: Store) { }

    ngOnInit() {
        this.store.toggleSideNav
  }

}
