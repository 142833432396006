import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AgentService } from 'src/app/_factory';
import { apiurl } from 'src/app/_factory/_api';
export interface Item {
  id: string,
  name: string,
  capital: string,
  phone: string,
  currency: string
}
export interface DownLineItem {
  item: Item,
  parent: DownLineItem
}
@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.css'],
  providers: [MessageService]
})
export class AddDepartmentComponent implements OnInit {
  @Input() topLevelDepartment;
  @Input() selectedDepartment;
  @Input() departmentFormTitle;
  @Input() items;
  @Input() actionType;
  @Output() emitCloseDeptForm: EventEmitter<any> = new EventEmitter();
  isSubDepartmentDialog = false;
  title: string;
  childered = [];
  positionRight = 'right';
  submitted;
  isParentChange = false;
  formInput: FormGroup;
  AllowParentSelection = true;
  RestructureWhenChildSameName = false;
  ShowFilter = true;
  Disabled = false;
  FilterPlaceholder = 'Select Department...';
  MaxDisplayed = 5;
  multipleSelected = [];
  simpleSelect = {}
  formAttribute: any = {
    departmentID: '',
    departmentName: '',
    departmentIsHidden: true,
    departmentIsEmpty: true,
    departmentSortIndex: 0,
    parent: '',
    parentName: '',
    children: [],
    name: ''
  }
  confirmToastPosition = 'top-center';
  entity;
  constructor(private _AgentService: AgentService, private messageService: MessageService) { }
    ngOnInit() {
        this.simpleSelect = this.topLevelDepartment;
    this.title = "ADD NEW DEPARTMENT";
    this.formAttribute = {
      departmentID: this.selectedDepartment && this.selectedDepartment.departmentID,
      departmentName: this.selectedDepartment && this.selectedDepartment.departmentName,
      departmentIsHidden: (this.selectedDepartment && this.selectedDepartment.departmentIsHidden) || false,
      departmentIsEmpty:  false,
      departmentSortIndex: 0,
      parent: this.selectedDepartment && this.selectedDepartment.parent,
      parentName: this.selectedDepartment && this.selectedDepartment.parentName,
      children: this.selectedDepartment && this.selectedDepartment.children,
      name: this.selectedDepartment && this.selectedDepartment.name || this.selectedDepartment && this.selectedDepartment.departmentName
    }
  }
  ngAfterViewInit = () => {
    if (this.actionType == 'edit')
      this.title = "DEPARTMENT"
  }

  handleCheckedItems(items) {
    this.isParentChange = true;
    const parent = items.parent
    this.formAttribute.parent = parent
  }
  handleSubDepartmentDialog = () => {
    this.isSubDepartmentDialog = true;
  }
  handleCloseSubDeptForm = (values) => {
    if (values) {
      this.formAttribute.children.push(values);
    }
    this.isSubDepartmentDialog = false;
  }
  handleClose = () => {
    this.emitCloseDeptForm.emit(null)
  }
  toastMsg(severity, summary, detail, position) {
    this.messageService.add({ key: 'addDepartment', severity: severity, summary: summary, detail: detail });
  }
  handleRemoveSubDepartment = (item, index) => {
    this.formAttribute.children.splice(index, 1);
  }
  handleSubmit = (myForm: NgForm) => {
    this.submitted = true;
    if (myForm.form.status !== 'INVALID') {
      this.submitted = false;
      let url = apiurl.departments.department;
      let departmentID = ''
      let method = 'post';
      const data: any = { ...this.formAttribute }
      let parent = '';
      let postData;
      if (!this.isParentChange) {
        parent = this.topLevelDepartment.departmentID
      } else {
        parent = this.formAttribute.parent
      }
      if (this.actionType == 'edit') {
        url = apiurl.departments.department_id.replace('{id}', this.selectedDepartment.departmentID.toString());
        departmentID = this.selectedDepartment.departmentID;
        method = 'put';
        postData = {
          "departmentID": departmentID,
          "departmentName": data.departmentName,
          "departmentIsHidden": data.departmentIsHidden,
          "departmentIsEmpty": data.departmentIsEmpty,
          "departmentSortIndex": data.departmentSortIndex,
          "isDeleted": data.isDeleted || false,
          "parent": parent,
          "children": data.children,
          "name": data.departmentName
        }
      } else {
        postData = {
          "departmentID": departmentID,
          "departmentName": data.departmentName,
          "departmentIsHidden": data.departmentIsHidden,
          "departmentIsEmpty": data.departmentIsEmpty,
          "departmentSortIndex": data.departmentSortIndex,
          "isDeleted": data.isDeleted || false,
          "parent": parent,
          "children": data.children,
          "name": data.departmentName
        }
      }
      this._AgentService.httpCall(url, method, postData, null, {}).subscribe((res) => {
        if (res && res.responseCode == 200) {
          this.toastMsg('success', 'Success', 'Department is added', 'top-right')
          setTimeout(() => {
            this.emitCloseDeptForm.emit(true)
          }, 3000);
        } else {
          this.toastMsg('warn', 'Warn', 'Something went wrong', 'top-right')
        }
      })
    }
  }
}
