import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CustomService } from '../_factory/custom.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private _CustomService: CustomService, private _Router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isSarch = localStorage.getItem('isSearch');
    if (!isSarch) {
      this._CustomService.show();
    }
    // const currentUser: any = this._CustomService.getAuthData();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const accessToken = currentUser && currentUser.auth_token ? currentUser.auth_token : null;
    req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      }
    });
    return next.handle(req).pipe(finalize(() => !isSarch ? this._CustomService.hide() : localStorage.removeItem('isSearch')));
  }
}
