import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AgentService, CustomService } from './_factory';
import { AuthService } from './_factory/auth.service';
import { Store } from './_store/store';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  authPage;
    authData: any = {};
   
  constructor(public store: Store, private _Router: Router, private _AgentService: AgentService, private _AuthenticationService: AuthService, private _CustomService: CustomService) { };
  ngOnInit() {
    setTimeout(() => {
      this.getAuthInfo();
      this._AuthenticationService.getAuthEmitter().subscribe((customObject) => {
        this.getAuthInfo();
      });
    }, 2000);
  }
  getAuthInfo = () => {
    console.log('dashbaord')
    this.authData = this._CustomService.getAuthData();
    if (this.authData && this.authData.isLoggedIn) {
      this.authPage = false
    } else {
      this.authPage = true
    }
  }
}
