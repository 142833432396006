import { Injectable } from '@angular/core';
import { action, observable } from 'mobx';
@Injectable()
export class Store {
    @observable toggleSideNav: boolean = false;
    @observable departmentData: any | null = {};
    @observable subDepartmentData: any | null = {};
    @observable departmentFormData: any | null = {};
    @observable productFilter: any | null = {};
    @observable storeNotificationList: any | null = null;
    @observable characteristicsList: any | null = null;

    constructor() { }
    @action emmitSideNav() {
        this.toggleSideNav = !this.toggleSideNav
    }
    @action departmentDataAction(data) {
        this.departmentData = data || {}
    }
    @action subDepartmentDataAction(data) {
        this.subDepartmentData = data || {}
    }
    @action departmentFormDataAction(data) {
        this.departmentFormData = data || {}
    }
    @action productFilterAction(data) {
        this.productFilter = data || {}
    }
    
    @action getNotificationAction(data) {
        this.storeNotificationList = data || {}
    }
}
